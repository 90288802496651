
export default [
  {
    path: "/role",
    name: "role",
    component: () => import('pages/roles/RolePage.vue'),
    title: "Role",
    redirect: { name: "list-role" },
    children: [
      {
        path: "list",
        name: "list-role",
        meta: {
          icon: "mdi-lock-check-outline",
          auth: true,
          titleTranslationKey: "roles.roles-list-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'roles.roles-list',
              icon: 'mdi-lock-check-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/roles/ListRoleComponent.vue')
      },
      {
        path: "create",
        name: "create-role",
        meta: {
          auth: true,
          titleTranslationKey: "roles.create-role",
          icon: "mdi-lock-plus-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'roles.roles-list',
              icon: 'mdi-lock-check-outline',
              disabled: false,
              exact: true,
              routeName: 'list-role',
            },
            {
              translationKey: 'roles.create-role',
              icon: 'mdi-lock-plus-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/roles/CreateRoleComponent.vue')
      },
      {
        path: "edit/:id",
        name: "edit-role",
        meta: {
          auth: true,
          titleTranslationKey: "roles.edit-role",
          icon: "mdi-lock-open-alert-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'roles.roles-list',
              icon: 'mdi-lock-check-outline',
              disabled: false,
              exact: true,
              routeName: 'list-role',
            },
            {
              translationKey: 'roles.edit-role',
              icon: 'mdi-lock-open-alert-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/roles/EditRoleComponent.vue'),
      },
      {
        path: "show/:id",
        name: "show-role",
        meta: {
          auth: true,
          titleTranslationKey: "roles.role-profile",
          icon: "mdi-lock-open-variant-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'roles.roles-list',
              icon: 'mdi-lock-check-outline',
              disabled: false,
              exact: true,
              routeName: 'list-role',
            },
            {
              translationKey: 'roles.role-profile',
              icon: 'mdi-lock-open-variant-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/roles/ShowRoleComponent.vue'),
      }
    ]
  }
];
