import { useUserStore } from "@/stores/user";
import { Cookies } from "quasar";

export default async function adminMiddleware({ next }) {
  const userStore = useUserStore();
  const me = userStore.me;
  const token = Cookies.has("authToken");
  if (!token) {
    return next({
      name: "login",
    });
  }

  if (!me?.is_admin) {
    return next({
      name: "access-denied",
    });
  }
  return next();
}
