
import redirectMiddleware from '@/middlewares/redirectMiddleware'
export default [
  {
    path: "login",
    name: "login",
    component: () => import('pages/auth/LoginPage.vue'),
    meta: {
      middleware: [redirectMiddleware]
    }
  },
  {
    path: "set-password",
    name: "set-password",
    props: route => ({ id: route.query.user, token: route.query.token }),
    component: () => import('pages/auth/SetPasswordPage.vue')
  },
  {
    path: "forgot-password",
    name: "forgot-password",
    component: () => import('pages/auth/ForgotPasswordPage.vue')
  },
  {
    path: "password/reset",
    name: "password-reset",
    props: route => ({ id: route.query.user, token: route.query.token, email: route.query.email }),
    component: () => import('pages/auth/ResetPasswordPage.vue')

  },
];
