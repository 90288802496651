import { Cookies } from "quasar";
export default async function redirectMiddleware({ to, next }) {
  const token = Cookies.has("authToken");
  if (token && (to.name === 'login')) {
    return next({
      name: "home",
    });
  }

  return next();
}
