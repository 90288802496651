import { boot } from 'quasar/wrappers'
import axios from 'axios'
import {
  LoadingBar,
  Notify,
  Cookies
} from 'quasar'

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)
LoadingBar.setDefaults({
  color: 'primary',
  position: 'top'
})
const api = axios.create({ baseURL: import.meta.env.VITE_APP_API_URL })

export default boot(({ app, router, store }) => {
  api.interceptors.request.use(async (config) => {
    LoadingBar.start()
    let token = Cookies.get('authToken')
    config.headers.Authorization = `Bearer ${token}`
    return config;
  }, (error) => {
    return Promise.reject(error);
  });

  api.interceptors.response.use((response) => {
    LoadingBar.stop()
    return response;
  }, (error) => {
    handleErrorResponse(error);
    LoadingBar.stop()
    throw error;
  });

  function handleErrorResponse(error) {
    if (error.response) {
      const status = error.response.status;

      if (
        status === 401 &&
        !error.response.config.__isRetryRequest
      ) {
        Notify.create({
          message: error.response.data.message,
          type: 'negative',
        })
        LoadingBar.stop()
        router.push({ name: 'login' })
        throw error.response.data
      }
      if (status === 400 || status === 404) {
        Notify.create({
          message: error.response.data.message,
          type: 'negative',
        })
        LoadingBar.stop()
        throw error.response.data


      } else if (status === 422 || status === 429) {
        Notify.create({
          message: error.response.data.message,
          type: 'negative',
        })
        LoadingBar.stop()
        throw error.response.data
      }
      else if (status >= 500) {
        Notify.create({
          message: error.response.data.message,
          type: 'negative',
        })
        LoadingBar.stop()
      }
      LoadingBar.stop()
      throw error.response;
    }

  }

  // for Composition API
  app.provide('api', api)
  app.provide('axios', axios)
})

export { axios, api }
