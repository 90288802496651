import { useUserStore } from "@/stores/user";
import { Loading, QSpinnerComment, Dark, LocalStorage, Cookies } from 'quasar'

export default async function authMiddleware({ to, next }) {
  const userStore = useUserStore();
  Loading.show({
    spinner: QSpinnerComment,
    backgroundColor: Dark.isActive ? 'dark' : 'white',
    spinnerColor: 'primary',
    spinnerSize: 140,
    messageColor: Dark.isActive ? 'white' : 'dark',
    message: '<div class="text-h5 text-uppercase">Welcome to <b>Chat Your Job</b></div><br/><div class="text-subtitle2 ">Powered by <b>DEKRA</b></div><br/><span class="text-italic">Please wait...</span>',
    html: true,
  })
  const token = Cookies.has("authToken");
  if (!token) {
    Loading.hide()
    return next({
      name: "login",
    });
  }
  const hasMe = LocalStorage.hasItem('me');
  if (!hasMe) {
    await userStore.loadMyUser();
  }
  Loading.hide()
  return next();
}
