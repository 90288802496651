import { colors } from 'quasar';

export default [
  {
    path: "/user",
    name: "user",
    component: () => import('pages/users/UserPage.vue'),
    title: "User",
    redirect: { name: "list-user" },
    children: [
      {
        path: "list",
        name: "list-user",
        meta: {
          icon: "mdi-account-group-outline",
          auth: true,
          titleTranslationKey: "users.users-list-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'users.users-list',
              icon: 'mdi-account-group-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/users/ListUserComponent.vue')
      },
      {
        path: "create",
        name: "create-user",
        meta: {
          auth: true,
          titleTranslationKey: "users.invite-user-title",
          icon: "mdi-account-multiple-plus-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'users.users-list',
              icon: 'mdi-account-group-outline',
              disabled: false,
              exact: true,
              routeName: 'list-user',
            },
            {
              translationKey: 'users.invite-user',
              icon: 'mdi-account-multiple-plus-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/users/CreateUserComponent.vue')
      },
      {
        path: "profile",
        name: "profile",
        meta: {
          auth: true,
          titleTranslationKey: "users.user-profile",
          icon: "mdi-card-account-details-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'users.users-list',
              icon: 'mdi-account-group-outline',
              disabled: false,
              exact: true,
              routeName: 'list-user',
            },
            {
              translationKey: 'app.my-account',
              icon: 'mdi-card-account-details-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/users/UserProfileComponent.vue'),
      },
      {
        path: "edit/:id",
        name: "edit-user",
        meta: {
          auth: true,
          titleTranslationKey: "users.edit-user",
          icon: "mdi-account-box",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'users.users-list',
              icon: 'mdi-account-group-outline',
              disabled: false,
              exact: true,
              routeName: 'list-user',
            },
            {
              translationKey: 'users.edit-user',
              icon: 'mdi-account-box',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/users/EditUserComponent.vue'),
      },
      {
        path: "show/:id",
        name: "show-user",
        meta: {
          auth: true,
          titleTranslationKey: "users.user-profile",
          icon: "mdi-account-box",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'users.users-list',
              icon: 'mdi-account-outline',
              disabled: false,
              exact: true,
              routeName: 'list-user',
            },
            {
              translationKey: 'users.user-profile',
              icon: 'mdi-account-box',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/users/ShowUserComponent.vue'),
      }
    ]
  }
];
