export default [
  {
    path: "/organization",
    name: "organization",
    component: () => import('pages/organizations/OrganizationPage.vue'),
    title: "Organization",
    redirect: { name: "list-organization" },
    children: [
      {
        path: "list",
        name: "list-organization",
        meta: {
          icon: "mdi-office-building-outline",
          titleTranslationKey: "organizations.organizations-list-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'organizations.organizations-list',
              icon: 'mdi-office-building-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/organizations/ListOrganizationComponent.vue')
      },
      {
        path: "create",
        name: "create-organization",
        meta: {
          auth: true,
          titleTranslationKey: "organizations.create-organization",
          icon: "mdi-office-building-plus-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'organizations.organizations-list',
              icon: 'mdi-office-building-outline',
              disabled: false,
              exact: true,
              routeName: 'list-organization',
            },
            {
              translationKey: 'organizations.create-organization',
              icon: 'mdi-office-building-plus-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/organizations/CreateOrganizationComponent.vue')
      },
      {
        path: "edit/:id",
        name: "edit-organization",
        meta: {
          auth: true,
          titleTranslationKey: "organizations.edit-organization",
          icon: "mdi-office-building-cog-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'organizations.organizations-list',
              icon: 'mdi-office-building-outline',
              disabled: false,
              exact: true,
              routeName: 'list-organization',
            },
            {
              translationKey: 'organizations.edit-organization',
              icon: 'mdi-office-building-cog-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/organizations/EditOrganizationComponent.vue'),
      },
      {
        path: "show/:id",
        name: "show-organization",
        meta: {
          auth: true,
          titleTranslationKey: "organizations.organization-profile",
          icon: "mdi-office-building-marker-outline",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'organizations.organizations-list',
              icon: 'mdi-office-building-outline',
              disabled: false,
              exact: true,
              routeName: 'list-organization',
            },
            {
              translationKey: 'organizations.organization-profile',
              icon: 'mdi-office-building-marker-outline',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/organizations/ShowOrganizationComponent.vue'),
      }
    ]
  }
];
