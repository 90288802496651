import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'
import routes from './routes'
import middlewarePipeline from './middleware-pipeline'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default route(function ({ store }) {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE === 'history' ? createWebHistory : createWebHashHistory)

  const Router = createRouter({
    scrollBehavior: () => ({ left: 0, top: 0 }),
    routes,

    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })

  /**
   * Run the middleware(s) using the beforeEach hook
   */
  Router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) return next()
    let middleware = to.meta.middleware
    let context = { to, from, next, store }
    return middleware[0]({
      ...context,
      next: middlewarePipeline(context, middleware, 1)
    })
  })

  return Router
})
