
import authMiddleware from '@/middlewares/authMiddleware'
import adminMiddleware from '@/middlewares/adminMiddleware'
import Settings from '@/router/settings'
import Auth from '@/router/auth'
import User from '@/router/user'
import Organization from '@/router/organization'
import Role from '@/router/role'
import Permission from '@/router/permission'
const routes = [
  {
    path: "/access-denied",
    name: "access-denied",
    component: () => import('pages/status/NoPermissionsPage.vue'),
  },
  {
    path: "/error-500",
    name: "500",
    component: () => import('pages/status/Error500Page.vue'),
  },
  {
    path: "/error-503",
    name: "503",
    component: () => import('pages/status/Error503Page.vue'),
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('layouts/AuthLayout.vue'),
    redirect: { name: "login" },
    children: [
      ...Auth
    ]
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('layouts/MainLayout.vue'),
    meta: {
      middleware: [authMiddleware, adminMiddleware]
    },
    redirect: { name: "home" },
    children: [
      {
        path: "/home",
        name: "home",
        component: () => import('pages/HomePage.vue')
      },
      ...Settings,
      ...User,
      ...Organization,
      ...Permission,
      ...Role
    ]
  },

  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import('pages/status/Error404Page.vue'),

  },
]

export default routes
