
export default [
  {
    path: "/permission",
    name: "permission",
    component: () => import('pages/permissions/PermissionPage.vue'),
    title: "Permission",
    redirect: { name: "list-permission" },
    children: [
      {
        path: "list",
        name: "list-permission",
        meta: {
          icon: "mdi-key-chain-variant",
          auth: true,
          titleTranslationKey: "permissions.permissions-list-title",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'permissions.permissions-list',
              icon: 'mdi-key-chain-variant',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/permissions/ListPermissionComponent.vue')
      },
      {
        path: "show/:id",
        name: "show-permission",
        meta: {
          auth: true,
          titleTranslationKey: "permissions.permission-profile",
          icon: "mdi-key-star",
          breadcrumbs: [
            {
              translationKey: 'nav-bar.home-title',
              icon: 'mdi-home',
              disabled: false,
              exact: true,
              routeName: 'home',
            },
            {
              translationKey: 'permissions.permissions-list',
              icon: 'mdi-key-chain-variant',
              disabled: false,
              exact: true,
              routeName: 'list-permission',
            },
            {
              translationKey: 'permissions.permission-profile',
              icon: 'mdi-key-star',
              disable: true,
            },
          ],
        },
        component: () => import('@/components/permissions/ShowPermissionComponent.vue'),
      }
    ]
  }
];
